import { createRouter, createWebHashHistory } from 'vue-router';
import i18n from '@/locales';
import routers, { setDocumentTitle, permissionRouters } from '@/router/utils/router.routing';

import UserStore from '@/store/user';
import CommonStore from '@/store/common';

const router = createRouter({
  history: createWebHashHistory(import.meta.env.VITE_APP_PUBLIC_PATH),
  routes: [],
});

// 路由守卫，根据权限控制跳转
router.beforeEach(async (to, from, next) => {
  const { name } = to;
  const { VITE_APP_TITLE } = import.meta.env;
  const userStore = UserStore();
  const commonStore = CommonStore();

  // 改变标题
  if (to.name) {
    setDocumentTitle(i18n.global.t(`menu.${String(name)}`), VITE_APP_TITLE);
  }

  // 假如没有userInfo对象

  if (!userStore.userInfo.hasOwnProperty('uid')) {
    // 获取userInfo信息
    await userStore.getUserInfo();
    // 异步之后再拿一遍userInfo
    // 通过权限判定某个路由对象是否有权限
    const routerList = permissionRouters(routers, userStore.userInfo.permissions);
    // 存储store里（给别的组件使用）
    commonStore.setRouters(routerList);
    const formQuery: any = from.query;
    // 请求带有 redirect 重定向时，登录自动重定向到该地址
    const redirect = decodeURIComponent(formQuery.redirect || to.path);
    if (to.path === redirect) {
      // 重定向的先跳重定项
      next({ ...to, replace: true });
    } else {
      // 跳转到目的路由
      next({ path: redirect });
    }
  } else if (!to.name && to.matched.length === 0) {
    // console.log(to);
    // 没有相应的路由跳404
    next({ path: '/404' });
  } else {
    // 默认跳转
    next();
  }
});

export default router;
