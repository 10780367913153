<template>
  <el-config-provider :locale="localeValue">
    <div id="router">
      <router-view></router-view>
    </div>
  </el-config-provider>
</template>

<script setup lang="ts">
import { RouterView, useRouter } from "vue-router";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import LangStore from "@/store/lang";
const { t, getLocaleMessage } = useI18n();

document.querySelector("body")!.setAttribute("style", "margin:0");

const localeValue = computed(() => {
  const langStore = LangStore();
  const lang = langStore.lang;
  const langPackage = JSON.parse(JSON.stringify(getLocaleMessage(lang)));
  return langPackage.element;
});
</script>

<style lang="less">
@import "@/assets/styles/global.less";
//引入light风格layout样式（左侧，头部menu样式等）不想用注掉就行了
@import "@/theme/index.less"; // 引入layout样式（左侧，头部menu等）
</style>
