export default {
  // common label
  'label.type': '类型',
  'label.number': '序号',
  'label.name': '名称',
  'label.content': '内容',
  'label.remark': '备注',
  'label.createTime': '创建时间',
  'label.operation': '操作',
  'label.code': '编码',
  'label.date': '日期',
  'label.title': '标题',
  'label.status': '状态',
  'label.modelName': '模型名称',
  'label.controlProject': '控制项目',
  'label.screeningFileName': '筛查文件名',
  'label.screeningTime': '筛查日期',
  'label.importSuccessCnt': '导入成功条数',
  'label.importFailedCnt': '导入失败条数',
  'label.riskyDataCnt': '风险项数',
  'label.modelNumber': '模型编号',
  'label.activationDate': '启用时间',
  'label.disabledDate': '禁用时间',
  'label.updateUser': '操作人',
  'label.updateTime': '操作时间',
  'label.department': '部门',
  'label.endpoint': '终端类型',
  'label.username': '用户名',
  'label.unitName': '部门名称',
  'label.UID': 'UID',
  'label.email': '邮箱',
  'label.mobile': '手机号',
  'label.yes': '是',
  'label.no': '否',
  'label.logout': "退出",
  'label.import': '导入',
  'label.export': '导出',
  'label.delete': '删除',
  'label.project': '自控项目',
  'label.modelRemark': '模型备注',
  'label.header/body': '报关单表头/表体',
  'label.field': '字段名称',
  'label.logic': '校验逻辑',
  'label.verify': '校验字段',
  'label.acm': '应用合规模型',
  'label.permission': '按钮权限',
  'label.system': '关务合规模型',
  'label.Screeningstatus': '筛查状态',
  // common button
  'button.search': '搜索',
  'button.enabled': '启用',
  'button.disabled': '禁用',
  'button.reset': '重置',
  'button.ComplianceScreening': '合规筛查',
  'button.cancel': '取消',
  'button.save': '保存',
  'button.add': '添加',
  'button.import': '导入',
  'button.export': '导出',
  'button.ok': '确定',
  'button.synchronize': '同步',
  'button.submit': '提交',
  'button.addRule': '添加规则',
  'button.download': '模板下载',
  'button.upload': '上传',

  // common message
  'message.add': '添加成功',
  'message.edit': '编辑成功',
  'message.delete.confirm': '确认要删除该记录吗？',
  'message.delete.success': '删除记录成功。',
  'message.rule': '当前信息格式不正确。',
  'message.read.success': '操作成功',
  'message.async.title': '确认要同步权限吗？',
  'message.async.success': '权限同步成功',
  'message.back': '返回首页',

  // table label
  'label.table.title': '结果包含',
  'label.table.items': '项',
  'label.table.edit': '编辑',
  'label.table.exportReport': '导出报告',
  'label.table.delete': '删除',
  'label.table.detail': '详情',
  'label.table.permission': '权限',
  'label.table.model': '模型基础信息',
  'label.table.rule': '模型规则配置',
  'label.table.prompt': '拖动文件放到这里',
  'label.table.select': '请选择对应的excel模板文件',

  // placeholder
  'placeholder.select': '请选择',
  'placeholder.input': '请输入',

  // Audit
  'label.audit.detail': '审计日志详情',

  // Delegation
  'label.delegation.id': '代理人UID',
  'label.delegation.name': '代理人姓名',
  'label.delegation.list': '代理人列表',
  'label.delegation.startDate': '开始日期',
  'label.delegation.endDate': '结束日期',
  'label.delegation.add': '添加代理',
  'label.delegation.edit': '编辑代理',

  'label.delegation.authorizer.id': '授权人工号',
  'label.delegation.authorizer.name': '授权人姓名',

  'rule.delegation.id': '代理人工号不能为空！',
  'rule.delegation.startTime': '开始时间不能为空！',
  'rule.delegation.endTime': '结束时间不能为空！',

  // Dict
  'label.dict.table': '字典表',
  'label.dict.key': '主键',
  'label.dict.cn': '中文',
  'label.dict.en': '英文',
  'label.dict.add': '添加字典',
  'label.dict.edit': '编辑字典',

  'rule.dict.code': '请输入字典编码',
  'rule.dict.name': '请输入字典名称',

  // Notification
  'label.notification.handle': '处理',
  'label.notification.ignore': '已读',
  'label.notification.read': '已读',
  'label.notification.unread': '未读',
  'label.notification.clear': '清空通知',
  'label.notification.more': '查看更多',

  // Department
  'label.department.title': '查看部门信息',
  'label.department.unit.name': '部门名称',
  'label.department.unit.id': '部门编号',
  'label.department.short.name': '部门简称',
  'label.department.parent.id': '父级部门',

  // permission
  'label.permission.name': '权限名称',
  'label.permission.code': '权限编码',
  'label.permission.action': '权限动作',
  'label.permission.data': '数据权限',
  'label.permission.title': '权限',
  'label.permission.description': '权限描述',

  // Role
  'label.role.code': '角色编码',
  'label.role.name': '角色名称',
  'label.role.isGlobal': '全局角色',
  'label.role.enabled': '启用',
  'label.role.disabled': '禁用',
  'label.role.description': '角色描述',
  'label.role.add': '新增角色',
  'label.role.edit': '编辑角色',
  'label.role.permission': '编辑权限',

  'rule.role.code': '角色编码不能为空！',
  'rule.role.name': '角色名称不能为空！',
  'rule.role.model': '所在企业不能为空！',

  // enterprise
  // 'label.enterprise.menu': '企业管理',
  'label.enterprise.code': '企业ID',
  'label.enterprise.name': '企业名称',
  'label.enterprise.type': '企业类型',
  'label.enterprise.users': '关联用户数',
  'label.enterprise.creationTime': '创建时间',
  'label.enterprise.updateTime': '更新时间',
  'label.enterprise.add': '新增企业',
  'label.enterprise.edit': '修改企业信息',

  // user
  'label.user.add': '新增用户',
  'label.user.edit': '修改用户',
  'label.user.roles': '角色列表',

  'rule.user.name': '用户名不能为空！',
  'rule.user.uid': 'uid不能为空！',
  'rule.user.deptCode': '部门名称不能为空！',
  'rule.user.email': 'email不能为空！',
  'rule.user.email.type': 'email格式不正确！',
  'rule.user.mobile': '手机号不能为空！',
  'rule.user.mobile.type': '手机号格式不正确！',

  // container
  'label.menu.search': '查询条件',
  'label.menu.table': '结果展示',
  'label.menu.close': '关闭',
  'label.menu.open': '展开',

  // menu
  'menu.home': '首页',
  'menu.system': '合规模型',
  'menu.system.rbac': '权限系统',
  'menu.rbac.user': '用户管理',
  'menu.library.cls': '智能归类',
  'menu.system.comod': '合规模块',
  'menu.system.comsce': '合规筛查',
  'menu.system.model': '模型使用管理',
  'menu.rbac.role': '角色管理',
  'menu.rbac.permission': '权限管理',
  'menu.rbac.department': '部门管理',
  'menu.rbac.enterprise': '企业管理',
  'menu.system.notification': '通知管理',
  'menu.system.dictionary': '数据字典',
  'menu.system.audit': '审计日志',
  'menu.system.delegation': '代理授权',
  'menu.system.notFound': '对不起，你访问的页面不存在',
};
