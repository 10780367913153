import { defineStore } from 'pinia'
import { getInfo } from '@/api/system/rbac';

const UserStore = defineStore('user', {
  state: () => {
    return {
      userInfo: <Record<string, any>>{}
    }
  },
  getters: {
    permissions(state) {
      const result: any = {};
      (state.userInfo.permissions || []).forEach((element: Record<string, any>) => {
        result[element.permission] = element.actions;
      });
      return result;
    },
  },
  actions: {
    async getUserInfo() {
      // @mark
      const result = await getInfo();
      // 通知模块是一定有权限的
      // const notification = {
      //   permission: 'notification',
      //   actions: ['query']
      // }
      // result.permissions.push(notification)
      this.userInfo = result
    }

  }
})


export default UserStore;
