import moment from 'moment';
import { createI18n } from 'vue-i18n';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import _enUS from '@/locales/lang/en-US';
import _zhCN from '@/locales/lang/zh-CN';
// default lang
const storage = window.localStorage;

const locales: any = {
  'en-US': {
    ..._enUS,
    element: en,
    moment: {
      name: 'eu'
      // locale: momentEU
    }
  },
  'zh-CN': {
    ..._zhCN,
    element: zhCn,
    moment: {
      name: 'zh-cn'
      // locale: momentCN
    }
  }
};

export const defaultLang = storage.getItem('platform-lang') || 'zh-CN';


const i18n: any = createI18n({
  legacy: false,
  globalInjection: true,
  locale: defaultLang,
  messages: locales,
});


export const setI18nLanguage: any = (lang: string) => {
  i18n.locale = lang;
  const locale = locales[lang];
  moment.updateLocale(locale.moment.name, locale.moment.locale);
  storage.setItem('platform-lang', lang);
  document.querySelector<HTMLElement>('html')!.setAttribute('lang', lang);
  return lang;
};
export default i18n;

